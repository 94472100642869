import {
    AdminPanelIcon,
    AssistantIcon,
    BookBookmark,
    Crosshair,
    FileText,
    GradingIcon,
    GridViewIcon,
    IntegrationsIcon,
    MetricIcon,
    Planet,
    PortalIcon,
    SquareHalf,
    Sticker,
    TeamIcon,
    TrafficSign,
    UserIcon
} from 'components/icons';
import { Okr, OkrsList } from 'pages/Okrs';
import { Initiative, InitiativesList } from 'pages/Initiatives';
import Teams from 'pages/Teams';
import { ProductItem, ProductsList } from 'pages/Products';
import Users from 'pages/Users';
import Integrations from 'pages/Integrations';
import { Actions, hasPermission } from 'utils/permissions';
import Roadmap from 'pages/Roadmap';
import Metrics from 'pages/Metrics';
import UserAccount from './components/UserAccount';
import UserLogo from 'common/UserLogo';
import Username from './components/Username';
import Assistant from 'pages/Assistant';
import { DependenciesList, DependencyView } from 'pages/Dependencies';
import { ReportMain, ReportsList } from 'pages/Reports';
import Ideas from 'pages/Ideas';
import { KnowledgeBase, KnowledgeBaseList } from 'pages/KnowledgeBase';
import JiraMapping from 'pages/Integrations/JiraMapping';
import InitiativeScores from 'pages/InitiativeScores';
import { SidebarMenu, SidebarMenuItem } from 'utils/types';
import Views from 'pages/Views';
import View from 'pages/Views/view';
import KBTeams from 'pages/KBTeams';
import KBGlossary from 'pages/KBGlossary';
import KBPersonas from 'pages/KBPersonas';
import KBGoals from 'pages/KBGoals';
import KBGeneral from '../../../pages/KBGeneral';

export const defaultMenuItems: SidebarMenu = {
    // views: {
    //     items: [],
    //     getMenuItems: (items: SidebarMenuItem[]) => { return items },
    //     maxLength: 3,
    //     actionButton: AddViewsButton,
    //     createButton: CreateViewsButton
    // },
    main: {
        items:
            [
                {
                    url: 'initiatives',
                    title: 'PRDs / Initiatives',
                    icon: SquareHalf,
                    component: InitiativesList,
                    permission: Actions.isNotWebbFontainAndIsNotComcast,
                    nestedRoutes: [
                        {
                            url: 'initiative',
                            component: Initiative
                        },
                        {
                            url: 'initiative/:id',
                            component: Initiative
                        },
                    ],
                },
                {
                    url: 'views',
                    title: 'Execution Views',
                    icon: () => <GridViewIcon />,
                    component: Views,
                    permission: Actions.isInternalAndWF,
                    nestedRoutes: [
                        {
                            url: 'view',
                            component: View
                        },
                        {
                            url: 'view/:id',
                            component: View
                        },
                    ]
                },
                {
                    url: 'roadmap',
                    title: 'Roadmap',
                    component: Roadmap,
                    icon: TrafficSign,
                    permission: Actions.isNotWebbFontainAndIsNotComcast,
                },
                {
                    url: 'catalog',
                    title: 'Product Catalog',
                    component: ProductsList,
                    icon: PortalIcon,
                    permission: Actions.isNotWebbFontainAndIsNotComcast,
                    nestedRoutes: [
                        {
                            url: 'product',
                            component: ProductItem,
                        },
                        {
                            url: 'product/:id',
                            component: ProductItem,
                        }
                    ]
                },
                {
                    url: 'okrs',
                    title: 'OKRs',
                    component: OkrsList,
                    icon: Crosshair,
                    permission: Actions.isNotWebbFontainAndIsNotComcast,
                    nestedRoutes: [
                        {
                            url: 'okr',
                            component: Okr
                        },
                        {
                            url: 'okr/:id',
                            component: Okr
                        },
                    ]
                },
                {
                    url: 'dependencies',
                    title: 'Dependency tickets',
                    component: DependenciesList,
                    icon: Sticker,
                    permission: Actions.isNotWebbFontainAndIsNotComcast,
                    nestedRoutes: [
                        {
                            url: 'dependency',
                            component: DependencyView,
                        },
                        {
                            url: 'dependency/:id',
                            component: DependencyView,
                        }
                    ]
                },
                {
                    url: 'reports',
                    title: 'Reports',
                    component: ReportsList,
                    icon: FileText,
                    permission: Actions.isNotWebbFontainAndIsNotComcast,
                    nestedRoutes: [
                        {
                            url: 'report',
                            component: ReportMain,
                        },
                        {
                            url: 'report/:id',
                            component: ReportMain,
                        }
                    ]
                },
            ]
    },
    ideas: {
        items: [
            {
                title: 'Knowledge base',
                component: KBTeams,
                icon: BookBookmark,
                url: '',
                // permission: Actions.internal,
                permission: Actions.isWebbFontainComcastOrIsInternal,
                sections: [
                    {
                        url: 'kb/teams',
                        title: 'Teams',
                        component: KBTeams,
                    },
                    {
                        url: 'kb/glossary',
                        title: 'Glossary',
                        component: KBGlossary,
                    },
                    {
                        url: 'kb/personas',
                        title: 'Personas',
                        component: KBPersonas,
                    },
                    {
                        url: 'kb/goals',
                        title: 'Goals',
                        component: KBGoals,
                    },
                    // {
                    //     url: 'kb/general',
                    //     title: 'General',
                    //     component: KBGeneral,
                    // },
                ],
            },
            {
                url: 'knowledge-base',
                title: 'Knowledge base',
                component: KnowledgeBaseList,
                icon: BookBookmark,
                permission: Actions.isNotWebbFontainAndIsNotComcast,
                nestedRoutes: [
                    {
                        url: 'knowledge',
                        component: KnowledgeBase,
                    },
                    {
                        url: 'knowledge/:id',
                        component: KnowledgeBase,
                    }
                ]
            },
            {
                url: 'ideas',
                title: 'Ideas',
                component: Ideas,
                icon: Planet,
                permission: Actions.isNotWebbFontainAndIsNotComcast,
            },
            {
                url: 'assistant',
                title: 'Ask me anything',
                component: Assistant,
                icon: () => <AssistantIcon />,
                permission: Actions.isNotWebbFontaine,
            },
        ]
    },
    admin: {
        items: [
            {
                url: '',
                title: <Username />,
                icon: UserLogo,
                onBottom: true,
                component: UserAccount,
                hasPopupMenu: true,
            },
            {
                url: '',
                title: 'Admin Panel',
                component: Teams,
                onBottom: true,
                icon: AdminPanelIcon,
                permission: Actions.adminPanel,
                hidden: true,
                subMenus: [
                    {
                        url: 'users',
                        title: 'Users',
                        component: Users,
                        icon: () => <UserIcon />,
                    },
                    {
                        url: 'teams',
                        title: 'Teams',
                        component: Teams,
                        permission: Actions.isNotComcast,
                        icon: () => <TeamIcon />,
                    },
                    {
                        url: 'metrics',
                        title: 'Metrics',
                        component: Metrics,
                        icon: () => <MetricIcon />,
                        permission: Actions.isNotWebbFontainAndIsNotComcast,
                    },
                    {
                        url: 'integrations',
                        title: 'Integrations',
                        component: Integrations,
                        permission: Actions.isNotComcast,
                        nestedRoutes: [
                            {
                                url: 'jiramapping',
                                component: JiraMapping,
                            }
                        ],
                        icon: () => <IntegrationsIcon />
                    },
                    {
                        url: 'initiativeScores',
                        title: 'Initiative Scores',
                        component: InitiativeScores,
                        icon: () => <GradingIcon />,
                        permission: Actions.isNotWebbFontainAndIsNotComcast,
                    },
                ]
            }
        ]

    }
}

export const getMenuItemsArray: (views?: any) => SidebarMenuItem[] = (views) => {
    return Object.values(defaultMenuItems).flatMap(el => {
        if (el.getMenuItems && views) {
            return views
        } else {
            return el.items
        }
    })
}

export const getFirstAvailableMenuItem = () => {
    const menuItem = getMenuItemsArray().find(el => (el.permission && hasPermission(el.permission)) || !el.permission);

    if (menuItem && menuItem.url === '' && menuItem.sections && menuItem.sections.length > 0) {
        return menuItem.sections[0] as SidebarMenuItem;
    }

    return menuItem as SidebarMenuItem;
};

export default defaultMenuItems;
