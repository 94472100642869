import store from 'store';
import { User } from './types';
import { considerAccountType } from './constants';

export enum Permissions {
    ROLE_ADMIN = 'Admin',
    ROLE_EDITOR = 'Editor',
    ROLE_VIEWER = 'Viewer',
}

export enum Actions {
    create,
    edit,
    delete,
    view,
    adminPanel,
    internal,
    internalAndSpecEmails,
    internalAndBestCompanies,
    createMetric,
    isNotWebbFontaine,
    isInternalAndWF,
    isNotComcast,
    isNotWebbFontainAndIsNotComcast,
    isComcastOrIsInternal,
    isWebbFontainComcastOrIsInternal
}

const Abilities = {
    [Permissions.ROLE_ADMIN]: [Actions.create, Actions.delete, Actions.edit, Actions.view, Actions.adminPanel, Actions.createMetric],
    [Permissions.ROLE_EDITOR]: [Actions.create, Actions.view],
    [Permissions.ROLE_VIEWER]: [Actions.view]
}


export const getUserPermissionName = () => {
    const user = store.getState().user;
    if (user.roles.length) {
        const userRole = user.roles[0].name as unknown as keyof typeof Permissions;
        return Permissions[userRole]
    }
    return ''
}

export const hasPermission = (action: Actions, object: any = {}): boolean => {
    const user = store.getState().user;
    const workspace = store.getState().user.workspace;
    if (user.roles.length) {
        const userRole = user.roles[0].name as unknown as keyof typeof Permissions;

        if (Abilities[Permissions[userRole]].includes(action)) {
            return true;
        }
    }
    if (object.owner && object.owner.id) {
        return object.owner.id === user.id
    }
    if (object.owners && object.owners.length) {
        return object.owners.some((owner: User) => owner.id === user.id)
    }

    if (action === Actions.internal) {
        return considerAccountType ? user.email.endsWith('@prodmap.ai') : true;
    }

    if (action === Actions.internalAndBestCompanies) {
        return considerAccountType ? user.email.endsWith('@prodmap.ai') || user.email.endsWith('@picsart.com') : true;
    }

    if (action === Actions.internalAndSpecEmails) {
        const specEmails = [
            'ashkhen.prodmap@gmail.com',
            'raffiohanian13@gmail.com',
            'arshak@picsart.com'
        ]

        return considerAccountType ? user.email.endsWith('@prodmap.ai') || specEmails.includes(user.email.trim()) : true;
    }

    if (action === Actions.isInternalAndWF) {
        const specEmails = [
            'ashkhen.prodmap@gmail.com',
            'raffiohanian13@gmail.com',
            'arshak@picsart.com',
            'robert.gasparyan@picsart.com',
            'anna.gasparyan@picsart.com',
            'hasmik.sargsyan@picsart.com'
        ]

        return considerAccountType ? user.email.endsWith('@prodmap.ai') || user.email.endsWith('@webbfontaine.com') || specEmails.includes(user.email.trim()) : true
    }

    if (action === Actions.isNotWebbFontaine && workspace) {
        return considerAccountType ? !isWebbFontaine() : true
    }

    if (action === Actions.isNotComcast && workspace) {
        return considerAccountType ? !isComcast() : true
    }

    if (action === Actions.isNotWebbFontainAndIsNotComcast && workspace) {
        return considerAccountType ? !isWebbFontaine() && !isComcast() : true;
    }

    if (action === Actions.isWebbFontainComcastOrIsInternal) {
        return considerAccountType ? isComcast() || isWebbFontaine() || user.email.endsWith('@prodmap.ai') : true;
    }
    return false
}

const isWebbFontaine = () => {
    const workspace = store.getState().user.workspace;
    const WEBB_FONTAINE_WORKSPACE_ID_PROD = 1540;
    const WEBB_FONTAINE_WORKSPACE_ID_DEV = 1408;
    return workspace.id === WEBB_FONTAINE_WORKSPACE_ID_PROD || workspace.id === WEBB_FONTAINE_WORKSPACE_ID_DEV

}

const isComcast = () => {
    const workspace = store.getState().user.workspace;
    const COMCAST_WORKSPACE_ID = 3;
    return workspace.id === COMCAST_WORKSPACE_ID && workspace.name === 'comcast-demo'
}

export const isInternalUser = (email: string) => {
    const fixedEmails = [
        'ashkhen.prodmap@gmail.com',
        'arshak@picsart.com',
        'raffiohanian13@gmail.com',
        'anna.gasparyan@picsart.com',
        'daniel.danghyan@picsart.com',
        'nare.danielyan@picsart.com',
        'srbuhi.aydinyan@picsart.com',
        'tigran.sargsyan@picsart.com',
        'robert.gasparyan@picsart.com',
        'hasmik.sargsyan@picsart.com'
    ];

    return considerAccountType ? email.endsWith('@prodmap.ai') || fixedEmails.includes(email) : true;
};
