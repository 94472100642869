import { useState, KeyboardEvent, useRef, useEffect } from 'react';
import { Flexbox, Snackbar, Button, TextArea, CustomTyphography, ConfirmationDialog, ActionsMenu, Loader, IconButton, Tooltip, CommonButton, Dialog, RichTextEditor } from 'components';
import classNames from 'classnames/bind';
import styles from './assistant.module.scss';
import { ChatIcon, CloseIcon, DeleteIcon, EditIcon, Logo, MenuOpenIcon, PlusCircle, SendIcon, ThumbDownOffAltIcon, ThumbUpOffAltIcon } from 'components/icons';
import UserLogo from 'common/UserLogo';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUserSession, getUserLatestSession, getUserSession, getUserSessions, renameUserSession, sendFeedback, sendMessage } from './assistant.api';
import { AIStreamingEndCharCode } from 'utils/constants';
import { userSelector } from 'store/user';
import { v4 as uuid } from 'uuid';
import { currentSessionLoadingSelector, deleteSession, messagesSelector, renameChat, sessionIdSelector, sessionsSelector, setMessages, setSessionId } from 'store/assistant-slice';
import { setToLocalStorage } from 'utils/localStorage';
import CommonInput from 'components/CommonInput';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw'
import { AMAUserFeedback, Message } from 'utils/types';

const classes = classNames.bind(styles);

function capitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export default () => {
    const dispatch = useDispatch();

    const [question, setQuestion] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [assistantStatus, setAssistantStatus] = useState<null | string>(null);
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
    const [openHistorySidebar, setOpenHistorySidebar] = useState(false);
    const [currentSessionId, setCurrentSessionId] = useState<null | string>(null);
    const [openRenameDialog, setOpenRenameDialog] = useState(false);
    const [chatTitle, setChatTitle] = useState('');
    const [prdPreviewDialog, setPrdPreviewDialog] = useState<{ open: boolean, content?: { [key: string]: string } }>({ open: false })
    const [feedbackStatus, setFeedbackStatus] = useState<'initial' | 'pending' | 'success' | null>(null)
    const [feedback, setFeedback] = useState<AMAUserFeedback | null>(null)
    const [latestMessageId, setLatestMessageId] = useState<number | null>(null)
    const [error, setError] = useState(false);
    const [streamedText, setStreamedText] = useState('');
    const [prdPreviewLoading, setPrdPreviewLoading] = useState(false);
    const [aiRefineDialog, setAiRefineDialog] = useState<{ open: boolean, field?: string }>({ open: false })
    const [refinedContent, setRefinedContent] = useState<string>('')

    const user = useSelector(userSelector);
    const sessionId = useSelector(sessionIdSelector);
    const messages = useSelector(messagesSelector);
    const sessions = useSelector(sessionsSelector);
    const currentSessionLoading = useSelector(currentSessionLoadingSelector);

    useEffect(() => {
        if (!sessionId) {
            const newSessionId = uuid();
            dispatch(setSessionId(newSessionId))
        }
    }, [sessionId]);

    useEffect(() => {
        dispatch(getUserLatestSession(user.id));
        dispatch(getUserSessions(user.id))
    }, [])

    const onKeyPress = (e: KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            onSendMessage();
        }
    };

    const chatContainerRef = useRef<HTMLDivElement>(null);

    const onSendMessage = async (responseType?: 'text' | 'prd_preview' | 'refine', prompt?: string) => {
        if (sessionId) {
            setFeedbackStatus('initial');
            let generatedText = '';
            let lastMessageType: 'text' | 'prd_preview' | 'refine' = responseType || 'text';
            let jsonResponse: any = {}
            let userPrompt: string = responseType === 'refine' ? `Make the following changes to the field "${aiRefineDialog.field}":\n ${refinedContent}` : prompt ? prompt : question

            const newMessages: Message[] = [
                {
                    from: 'user',
                    text: userPrompt
                },
                ...messages
            ];
            dispatch(setMessages(newMessages));
            setQuestion('');
            setRefinedContent('')
            setIsLoading(true)
            setAiRefineDialog(prev => ({ ...prev, open: false }))
            setPrdPreviewDialog({ open: false })

            dispatch(
                sendMessage(
                    userPrompt,
                    sessionId,
                    (value: any, type?: 'log' | 'response' | 'message_id') => {
                        if (type === 'log') {
                            setAssistantStatus(value as string)
                        } else if (type === 'message_id') {
                            setLatestMessageId(value)
                        } else {
                            setAssistantStatus(null);
                            let text = '';

                            if (value.type === 'text' || value.type === 'refine') {
                                text = value.content;

                                if (text.charCodeAt(0) === AIStreamingEndCharCode) {
                                    value.type = lastMessageType;
                                }
                            } else if (value.type === 'prd_preview') {
                                jsonResponse = value.content
                                text = Object.values(value.content).join('\n')
                            }
                            generatedText = (generatedText + text).replace('```html', '')
                            setStreamedText(generatedText);

                            lastMessageType = value.type;

                            if (text.charCodeAt(0) === AIStreamingEndCharCode) {
                                setIsLoading(false)
                                const updatedMessages: Message[] = [
                                    {
                                        from: 'assistant',
                                        text: {
                                            type: responseType || lastMessageType,
                                            content: lastMessageType === 'prd_preview' ? jsonResponse : generatedText.trim().replace('```', '')
                                        }
                                    },
                                    ...newMessages
                                ];
                                dispatch(setMessages(updatedMessages));
                                setStreamedText('');
                                return;
                            }
                        }
                    },
                    () => {
                        setStreamedText('');
                        setIsLoading(false)
                        setError(true)
                    }
                )
            );
        }
    };

    useEffect(() => {
        if (sessionId) {
            setToLocalStorage(sessionId, sessionId);
        }
    }, [sessionId])

    const handleDeleteChat = async () => {
        if (currentSessionId) {
            try {
                await dispatch(deleteUserSession({ userId: user.id, sessionId: currentSessionId }))
                dispatch(deleteSession(currentSessionId));

                if (sessionId === currentSessionId) {
                    handleStartNewSession()
                }
            } catch (error) {
                console.log(error);
            }
        }
        setOpenDeleteConfirmation(false)
        setCurrentSessionId(null)
    }

    const showDeleteConfirmation = (sessionId: string) => {
        setCurrentSessionId(sessionId)
        setOpenDeleteConfirmation(true)
    }

    const onCancelDelete = () => {
        setOpenDeleteConfirmation(false)
    }

    const handleSelectSession = (sessionId: string) => {
        try {
            dispatch(getUserSession({ userId: user.id, sessionId: sessionId }))
        } catch (error) {
            console.log(error);
        }
    }

    const handleStartNewSession = () => {
        const newSessionId = uuid();
        dispatch(setSessionId(newSessionId))
        dispatch(setMessages([]))
    }

    const resetRenameDialog = () => {
        setOpenRenameDialog(false)
        setCurrentSessionId(null);
        setChatTitle('')
    }

    const handleRenameChat = async () => {
        if (currentSessionId) {
            try {
                await dispatch(renameUserSession({ userId: user.id, sessionId: currentSessionId, data: { title: chatTitle } }));
                dispatch(renameChat({ id: currentSessionId, title: chatTitle }))
            } catch (err) {
                console.log(err);
            }
        }

        resetRenameDialog()
    }

    const onResetFeedback = () => {
        setFeedbackStatus(null);
        setFeedback(null)
    }

    const handleSendFeedback = (feedbackType: 'positive' | 'negative') => {
        setFeedback({ type: feedbackType, content: '' })
        setFeedbackStatus('pending')
    }

    const handleSubmitFeedback = async () => {
        if (latestMessageId && sessionId && feedback) {
            try {
                await dispatch(sendFeedback({ userId: user.id, sessionId, messageId: latestMessageId, body: feedback }))
                setFeedbackStatus('success');
            } catch (err) {
                console.log(err);
            }

            setTimeout(() => {
                onResetFeedback()
            }, 1500);
        }
    }

    useEffect(() => {
        if (sessions.every(session => session.session_id !== sessionId) && messages.length >= 2) {
            dispatch(getUserSessions(user.id))
        }
    }, [messages.length, sessionId, sessions.length])

    useEffect(() => {
        if (chatContainerRef.current && streamedText) {
            chatContainerRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
            });
        }
    }, [streamedText, messages]);

    return (
        <Flexbox fullWidth className={classes('assistantContainer')}>
            {
                openHistorySidebar && (
                    <Flexbox vertical className={classes('history', 'gap-3')}>
                        <CommonButton
                            buttonType='shadow'
                            variant='text'
                            startIcon={<PlusCircle />}
                            onClick={handleStartNewSession}
                        >
                            New Session
                        </CommonButton>
                        <Flexbox vertical className={classes('gap-3', 'sessions')}>
                            {
                                sessions.map(session => {
                                    return (
                                        <Flexbox
                                            justifyBetween
                                            key={session.session_id}
                                            className={classes('history-item', { active: session.session_id === sessionId })}
                                            onClick={() => handleSelectSession(session.session_id)}
                                        >
                                            <CustomTyphography className={classes('ellipsisText')}>{session.title}</CustomTyphography>
                                            <ActionsMenu className={classes('sessionActions-btn')}
                                                buttonItems={[
                                                    {
                                                        label: 'Rename chat',
                                                        action: () => {
                                                            setOpenRenameDialog(true);
                                                            setCurrentSessionId(session.session_id)
                                                            setChatTitle(session.title)
                                                        },
                                                        icon: <EditIcon />
                                                    },
                                                    { label: 'Delete chat', action: () => showDeleteConfirmation(session.session_id), icon: <DeleteIcon />, type: 'red' },
                                                ]}
                                            />
                                        </Flexbox>
                                    )
                                })
                            }
                        </Flexbox>
                    </Flexbox>
                )
            }
            <Flexbox className={classes('messengerContainer')} fullWidth vertical ref={chatContainerRef}>
                <Flexbox className={classes('messagesContainer')} fullWidth>
                    {currentSessionLoading ? <Flexbox justify fullWidth className={classes('p-4')}><Loader /></Flexbox> : <>
                        {
                            (!!assistantStatus || isLoading) && (
                                <Flexbox className={classes('messageContainer', { fromAI: streamedText.length })}>
                                    <Flexbox className={classes('logoContainer')}>
                                        <Logo />
                                    </Flexbox>
                                    <Flexbox>
                                        {streamedText.length ? (
                                            <Flexbox
                                                fullWidth
                                                vertical
                                            >
                                                <Markdown remarkPlugins={[remarkGfm]}>{streamedText}</Markdown>
                                            </Flexbox>
                                        ) : (
                                            <Flexbox className={classes('gap-1')}>
                                                {assistantStatus &&
                                                    <Flexbox align className={classes('assistantStatus')}>
                                                        <CustomTyphography className={classes('assistantStatus-message')}>{assistantStatus}</CustomTyphography>
                                                    </Flexbox>
                                                }
                                                <Flexbox align className={classes('dotFlashingContainer')}>
                                                    <Flexbox className={classes('dotFlashing')} />
                                                </Flexbox>
                                            </Flexbox>
                                        )}
                                    </Flexbox>
                                </Flexbox>
                            )
                        }

                        {messages.map((message, index) => (
                            <Flexbox vertical key={index} fullWidth>
                                <Flexbox
                                    className={classes('messageContainer', { fromAI: message.from === 'assistant' })}
                                >
                                    <Flexbox className={classes('logoContainer')}>
                                        {message.from === 'user' ? <UserLogo /> : <Logo />}
                                    </Flexbox>
                                    {message.from === 'user' ? (
                                        <Flexbox className={classes('message')}>{message.text}</Flexbox>
                                    ) : (
                                        <Flexbox
                                            fullWidth
                                            vertical
                                        >
                                            {message.text.type === 'text' ? (
                                                <Markdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
                                                    {
                                                        message.text.content.replaceAll('```html', '').replaceAll('```', '')
                                                    }
                                                </Markdown>
                                            ) : message.text.type === 'prd_preview' ? (
                                                <Flexbox vertical className={classes('gap-6')}>
                                                    <Flexbox>PRD is Ready!</Flexbox>
                                                    <Button
                                                        sx={{
                                                            width: '175px',
                                                        }}
                                                        variant='contained'
                                                        onClick={() => {
                                                            setPrdPreviewDialog({ open: true, content: message.text.content as { [key: string]: string } })
                                                            setPrdPreviewLoading(true)
                                                            setTimeout(() => {
                                                                setPrdPreviewLoading(false)
                                                            }, 1000);
                                                        }}
                                                    >
                                                        Click to review
                                                    </Button>
                                                </Flexbox>
                                            ) : message.text.type === 'refine' ? (
                                                <Flexbox vertical fullWidth className={classes('gap-2')}>
                                                    <Markdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
                                                        {
                                                            message.text.content.replaceAll('```html', '').replaceAll('```', '')
                                                        }
                                                    </Markdown>
                                                    <Flexbox align className={classes('gap-2')}>
                                                        <Button
                                                            variant='contained'
                                                            onClick={() => {
                                                                onSendMessage(undefined, 'Show the full updated PRD')
                                                            }}
                                                        >
                                                            Looks Good
                                                        </Button>
                                                        <Button
                                                            variant='outlined'
                                                            onClick={() => {
                                                                setAiRefineDialog(prev => ({ ...prev, open: true }))
                                                            }}
                                                        >
                                                            Lets refine further
                                                        </Button>
                                                    </Flexbox>
                                                </Flexbox>
                                            ) : <Flexbox></Flexbox>}
                                        </Flexbox>
                                    )}
                                </Flexbox>
                                {message.from === 'assistant' && index === 0 && !!feedbackStatus && (
                                    <Flexbox justify align className={classes('userFeedbackContainer', 'gap-2')}>
                                        {feedbackStatus === 'success' ? <CustomTyphography>Thank you for your feedback!</CustomTyphography> :
                                            <>
                                                <CustomTyphography>Is this conversation helpful so far?</CustomTyphography>
                                                <Flexbox align fullHeight className={classes('rightBorder', 'gap-1', 'pr-2')}>
                                                    <IconButton onClick={() => handleSendFeedback('positive')}>
                                                        <ThumbUpOffAltIcon />
                                                    </IconButton>
                                                    <IconButton onClick={() => handleSendFeedback('negative')}>
                                                        <ThumbDownOffAltIcon />
                                                    </IconButton>
                                                </Flexbox>
                                                <IconButton onClick={() => setFeedbackStatus(null)}>
                                                    <CloseIcon />
                                                </IconButton>
                                            </>
                                        }
                                    </Flexbox>
                                )}
                            </Flexbox>
                        )
                        )}
                    </>
                    }
                </Flexbox>

                <Flexbox className={classes('newMessageContainer')} fullWidth>
                    <Flexbox align className={classes('gap-1')}>
                        <Tooltip title={`${openHistorySidebar ? 'Close' : 'Open'} Chat History`}>
                            <IconButton
                                className={classes('action-btn')}
                                disabled={!sessions.length}
                                onClick={() => setOpenHistorySidebar(prev => !prev)}
                            >
                                {openHistorySidebar ? <CloseIcon /> : <MenuOpenIcon />}
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={'New Chat'}>
                            <IconButton
                                className={classes('action-btn')}
                                onClick={handleStartNewSession}
                            >
                                <ChatIcon />
                            </IconButton>
                        </Tooltip>
                    </Flexbox>

                    <TextArea
                        value={question}
                        onKeyDown={onKeyPress}
                        onChange={e => {
                            setQuestion(e.target.value);
                        }}
                        placeholder="Ask here"
                        className={classes('textArea')}
                        fullWidth
                        endAdornment={
                            <SendIcon className={classes('sendIcon')} onClick={() => onSendMessage()} />
                        }
                        autoFocus
                        disabled={isLoading}
                    />
                </Flexbox>
                <ConfirmationDialog
                    open={openDeleteConfirmation}
                    onClose={onCancelDelete}
                    onConfirm={handleDeleteChat}
                    confirmButtonStyle='danger'
                    title='Delete the history?'
                >
                    <Flexbox>
                        Are you sure you want to clear the chat history?
                    </Flexbox>
                </ConfirmationDialog>

                <Snackbar open={error} onClose={() => setError(false)} type="error">
                    <Flexbox>Sorry, An error occurred. Please try again later!</Flexbox>
                </Snackbar>
                <Snackbar open={error} onClose={() => setError(false)} type="error">
                    <Flexbox>Chat deleted successfully!</Flexbox>
                </Snackbar>
            </Flexbox>

            <Dialog
                onClose={resetRenameDialog}
                open={openRenameDialog}
                cancelButton
                title="Rename Chat"
                confirmButton
                onConfirm={handleRenameChat}
                disabled={!chatTitle}
                PaperProps={{
                    sx: {
                        width: '450px'
                    }
                }}
            >
                <Flexbox fullWidth>
                    <CommonInput value={chatTitle} onChange={(e) => setChatTitle(e.target.value)} />
                </Flexbox>
            </Dialog>

            <Dialog
                onClose={() => setPrdPreviewDialog({ open: false })}
                open={prdPreviewDialog.open}
                title="PRD Preview"
                PaperProps={{
                    sx: {
                        width: '650px',
                        height: '100%'
                    }
                }}
            >
                <Flexbox fullWidth vertical className={classes('gap-4', 'p-4')}>
                    {prdPreviewLoading ? <Flexbox align justify className={classes('pt-4')}><Loader /></Flexbox> : <>
                        {prdPreviewDialog.content && Object.entries(prdPreviewDialog.content).map(([key, value]) => (
                            <Flexbox key={key} align vertical>
                                <Flexbox align
                                    className={classes('cursor-pointer', 'gap-1', 'refine-btn')}
                                    onClick={() => {
                                        setAiRefineDialog({
                                            open: true,
                                            field: key
                                        })
                                    }}
                                >
                                    <Logo />
                                    <CustomTyphography>AI Refine</CustomTyphography>
                                </Flexbox>
                                <RichTextEditor
                                    key={key}
                                    value={value || ''}
                                    placeholder={capitalize(key)}
                                    label={capitalize(key)}
                                    readOnly
                                    onChange={() => { }}
                                />
                            </Flexbox>
                        ))}
                    </>}
                </Flexbox>
            </Dialog>
            {
                feedback && <Dialog
                    open={feedbackStatus === 'pending'}
                    onClose={handleSubmitFeedback}
                    onConfirm={handleSubmitFeedback}
                    title='Feedback'
                    description='Please provide details: (optional)'
                    PaperProps={{
                        sx: {
                            width: '450px'
                        }
                    }}
                    confirmButton
                    confirmButtonLabel='Send Feedback'
                >
                    <Flexbox>
                        <TextArea
                            value={feedback.content}
                            onChange={e => setFeedback({ type: feedback.type, content: e.target.value })}
                            placeholder={feedback.type === 'positive' ? 'What was satisfying about this response?' : 'What can be improved?'}
                            className={classes('textArea')}
                            fullWidth
                            rows={3}
                        />
                    </Flexbox>
                </Dialog>
            }
            {
                aiRefineDialog.open && <Dialog
                    open={aiRefineDialog.open}
                    onClose={() => setAiRefineDialog(prev => ({ ...prev, open: false }))}
                    onConfirm={() => onSendMessage('refine')}
                    title={capitalize(aiRefineDialog?.field || '')}
                    PaperProps={{
                        sx: {
                            width: '550px'
                        }
                    }}
                    confirmButton
                    confirmButtonLabel='Confirm'
                    disabled={!refinedContent}
                >
                    <Flexbox>
                        <TextArea
                            value={refinedContent}
                            onChange={e => {
                                setRefinedContent(e.target.value)
                            }}
                            placeholder={'What would you like to change?'}
                            className={classes('textArea')}
                            fullWidth
                            rows={3}
                        />
                    </Flexbox>
                </Dialog>
            }
        </Flexbox>
    );
};
