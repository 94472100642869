import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store';

import request from 'utils/request';
import { KBTypes } from 'utils/types';

export const addKnowledge = createAsyncThunk(
    'kb/addKnowledge',
    async (data: { file: File, type: KBTypes, name: string }, { getState }) => {
        const storeState = getState() as RootState;

        const formData = new FormData();
        formData.append('file', data.file);
        formData.append('type', data.type);
        formData.append('name', data.name);

        const url = `/workspaces/${storeState.user.workspace.id}/kb`;
        const response = await request.post(
            url,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                timeout: 1000 * 180,
            }
        );

        return response.data;
    }
);

export const getKnowledgeByType = createAsyncThunk(
    'kb/getKnowledgeByType',
    async (type: KBTypes, { getState }) => {
        const storeState = getState() as RootState;

        const url = `/workspaces/${storeState.user.workspace.id}/kb/${type}/items`;
        const response = await request.get(url);

        return response.data;
    }
);

export const getKBSources = createAsyncThunk(
    'kb/getKBSources',
    async (type: KBTypes, { getState }) => {
        const storeState = getState() as RootState;

        const url = `/workspaces/${storeState.user.workspace.id}/kb/${type}/sources`;
        const response = await request.get(url);

        return response.data;
    }
);

export const deleteKnowledgeSource = createAsyncThunk(
    'kb/deleteKnowledgeSource',
    async (id: number, { getState }) => {
        const storeState = getState() as RootState;

        const url = `/workspaces/${storeState.user.workspace.id}/kb/${id}`;
        const response = await request.delete(url);

        return response.data;
    }
);

