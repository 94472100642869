import Flexbox from 'components/Flexbox'
import styles from './styles.module.scss'
import classNames from 'classnames/bind';
import { FC, useEffect, useState } from 'react';
import { ClickAwayListener, CommonButton, CustomSnackbar, CustomTyphography, Loader } from 'components';
import AddSourceSection from './AddSourceSection';
import SourcePrimaryHeading from './SourcePrimaryHeading';
import Source from './Source';
import { useDispatch, useSelector } from 'react-redux';
import { getKBSources } from 'store/knowledgeBase-api';
import { KBTypes } from 'utils/types';
import { knowledgeSourcesErrorSelector, knowledgeSourcesLoadingSelector, knowledgeSourcesSelector, setKBSources } from 'store/knowledgeBase-slice';
import Popper from '@mui/material/Popper';
import { Actions, hasPermission } from 'utils/permissions';

const classes = classNames.bind(styles);

interface KnowledgeSourcePopupProps {
    kbType: KBTypes,
    onOpenAddSourcePopup?: () => void
}

const KnowledgeSourcePopup: FC<KnowledgeSourcePopupProps> = ({ kbType, onOpenAddSourcePopup }) => {
    const dispatch = useDispatch()
    const [openAddSourceSection, setOpenAddSourceSection] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const sources = useSelector(knowledgeSourcesSelector)
    const sourcesLoading = useSelector(knowledgeSourcesLoadingSelector)
    const sourcesError = useSelector(knowledgeSourcesErrorSelector)

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    useEffect(() => {
        if (open && !sources.length) {
            dispatch(getKBSources(kbType))
        }
    }, [open, kbType, sources.length])

    useEffect(() => {
        dispatch(setKBSources([]))
    }, [kbType])

    const handleCloseAddSourceSection = () => {
        setOpenAddSourceSection(false)
    }

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    return (
        <>
            <Flexbox id={id} justifyBetween align className={classes('kbSources', 'sourcePopupButton', 'cursor-pointer')} onClick={handleClick}>
                <SourcePrimaryHeading open={open} title={'Manage Sources'} endIcon />
            </Flexbox>
            <Popper id={id} open={open} anchorEl={anchorEl} sx={{
                zIndex: 10
            }}>
                <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                    <Flexbox vertical className={classes('kbSources', 'sourcePopupLinked', 'gap-4', 'sourcePopup')}>
                        {
                            openAddSourceSection ? <AddSourceSection handleCloseAddSourceSection={handleCloseAddSourceSection} /> : <>
                                <Flexbox justifyBetween align className={classes('sourcePopupLinked-heading', 'pb-4')}>
                                    <SourcePrimaryHeading open title={'Manage Sources'} />
                                </Flexbox>
                                {
                                    sourcesLoading ? <Flexbox justify className={classes('pt-6')}><Loader /></Flexbox> : (
                                        <Flexbox vertical justifyBetween fullHeight className={classes('gap-4', 'overflow-hidden')}>
                                            <CustomTyphography className={classes('sourcesCountTitle')}>Added Sources ({sources.length})</CustomTyphography>
                                            <Flexbox vertical className={classes('sourcePopupLinked-main', 'gap-4', 'overflow-auto')}>
                                                {
                                                    sources.map(source => {
                                                        return <Source source={source} key={source.id} />
                                                    })
                                                }
                                            </Flexbox>
                                            {hasPermission(Actions.create) && (
                                                <Flexbox vertical className={classes('sourcePopupLinked-footer', 'gap-2')}>
                                                    <CommonButton buttonSize='large' buttonType='primary' fullWidth onClick={onOpenAddSourcePopup}>Add New Source</CommonButton>
                                                    {/* <CustomTyphography className={classes('sourcePopupLinked-footerText')}>Last updated {timeago.format(lastUpdatedDate)}...</CustomTyphography> */}
                                                </Flexbox>
                                            )}
                                        </Flexbox>
                                    )
                                }
                            </>
                        }
                    </Flexbox>
                </ClickAwayListener>
            </Popper>
            <CustomSnackbar open={!!sourcesError} type='error' title={sourcesError} />
        </>
    )
}

export default KnowledgeSourcePopup