import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store';
import { addKnowledge, deleteKnowledgeSource, getKBSources, getKnowledgeByType } from './knowledgeBase-api';
import { GlossaryKnowledgeItem, TeamKnowledgeItem } from 'utils/types';

interface KnowledgeSource {
    id: number,
    name: string,
    sources: {
        source: string
    }[]
}

interface knowledgeBaseState {
    addKnowledgeLoading?: boolean
    addKnowledgeError?: string
    getKnowledgeByTypeLoading?: boolean
    getKnowledgeByTypeError?: string,
    knowledgeItems: (TeamKnowledgeItem | GlossaryKnowledgeItem)[],
    updateKnowledgeLoading?: boolean,
    updateKnowledgeError?: string
    knowledgeSources: KnowledgeSource[]
    getKBSourcesLoading?: boolean
    getKBSourcesError?: string
    deleteSourceLoading?: boolean
}
const initialState: knowledgeBaseState = {
    knowledgeItems: [],
    knowledgeSources: []
}

const knowledgeBaseSlice = createSlice({
    name: 'knowledgeBaseNew',
    initialState,
    reducers: {
        deleteKbSource: (state, action: PayloadAction<number>) => {
            state.knowledgeSources = state.knowledgeSources.filter(source => source.id !== action.payload)
            state.knowledgeItems = state.knowledgeItems.filter(kbItem => kbItem.knowledgeBaseId !== action.payload)
        },
        setKBSources: (state, action: PayloadAction<KnowledgeSource[]>) => {
            state.knowledgeSources = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(addKnowledge.pending, (state) => {
                state.addKnowledgeLoading = true;
            })
            .addCase(addKnowledge.fulfilled, (state, action) => {
                state.addKnowledgeLoading = false;
                state.knowledgeItems = [...state.knowledgeItems, ...action.payload.knowledgeItems]
            })
            .addCase(addKnowledge.rejected, (state, action) => {
                state.addKnowledgeLoading = false;
                state.addKnowledgeError = action.error.message;
            })
            .addCase(getKnowledgeByType.pending, (state) => {
                state.getKnowledgeByTypeLoading = true;
            })
            .addCase(getKnowledgeByType.fulfilled, (state, action) => {
                state.getKnowledgeByTypeLoading = false;
                state.knowledgeItems = action.payload
            })
            .addCase(getKnowledgeByType.rejected, (state, action) => {
                state.getKnowledgeByTypeLoading = false;
                state.getKnowledgeByTypeError = action.error.message;
            })
            .addCase(getKBSources.pending, (state) => {
                state.getKBSourcesLoading = true;
            })
            .addCase(getKBSources.fulfilled, (state, action) => {
                state.getKBSourcesLoading = false;
                state.knowledgeSources = action.payload
            })
            .addCase(getKBSources.rejected, (state, action) => {
                state.getKBSourcesLoading = false;
                state.getKBSourcesError = action.error.message;
            })
            .addCase(deleteKnowledgeSource.pending, (state) => {
                state.deleteSourceLoading = true;
            })
            .addCase(deleteKnowledgeSource.fulfilled, (state, action) => {
                state.deleteSourceLoading = false;
            })
            .addCase(deleteKnowledgeSource.rejected, (state, action) => {
                state.deleteSourceLoading = false;
            })
    },
})

export const addKnowledgeLoadingSelector = (store: RootState) => store.knowledgeBaseNew.addKnowledgeLoading;
export const addKnowledgeErrorSelector = (store: RootState) => store.knowledgeBaseNew.addKnowledgeError;
export const getKnowledgeLoadingSelector = (store: RootState) => store.knowledgeBaseNew.getKnowledgeByTypeLoading;
export const getKnowledgeErrorSelector = (store: RootState) => store.knowledgeBaseNew.getKnowledgeByTypeError;
export const knowledgeItemsSelector = (store: RootState) => store.knowledgeBaseNew.knowledgeItems;
export const knowledgeSourcesSelector = (store: RootState) => store.knowledgeBaseNew.knowledgeSources;
export const knowledgeSourcesLoadingSelector = (store: RootState) => store.knowledgeBaseNew.getKBSourcesLoading;
export const knowledgeSourcesErrorSelector = (store: RootState) => store.knowledgeBaseNew.getKBSourcesError;
export const deleteSourceLoadingSelector = (store: RootState) => store.knowledgeBaseNew.deleteSourceLoading;

export const {
    deleteKbSource,
    setKBSources
} = knowledgeBaseSlice.actions;

export default knowledgeBaseSlice;
