import { CommonButton, CustomSnackbar, CustomTyphography, Dialog, Flexbox } from 'components';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { ChangeEvent, FC, useState } from 'react';
import CommonInput from 'components/CommonInput';
import UploadFileInput from 'components/CommonInput/UploadFileInput';
import { KBTypes } from 'utils/types';
import { addKnowledge } from 'store/knowledgeBase-api';
import { useDispatch, useSelector } from 'react-redux';
import { addKnowledgeErrorSelector, addKnowledgeLoadingSelector } from 'store/knowledgeBase-slice';

const classes = classNames.bind(styles);

// const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB in bytes

interface AddKnowledgeSourcePopupProps {
    open: boolean;
    handleClosePopup: () => void;
    kbType: KBTypes;
    description?: string;
}

const AddKnowledgeSourcePopup: FC<AddKnowledgeSourcePopupProps> = ({
    open,
    kbType,
    handleClosePopup,
    description,
}) => {
    const dispatch = useDispatch()

    const [knowledgeTitle, setKnowledgeTitle] = useState('')
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [uploadFilerrorMessage, setUploadFilerrorMessage] = useState('');

    const addKnowledgeLoading = useSelector(addKnowledgeLoadingSelector);
    const addKnowledgeError = useSelector(addKnowledgeErrorSelector)

    const onClosePopup = () => {
        handleClosePopup();
        setSelectedFile(null);
        setKnowledgeTitle('');
        setUploadFilerrorMessage('')
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            // if (file.size > MAX_FILE_SIZE) {
            //     setUploadFilerrorMessage('File size exceeds 2MB. Please select a smaller file.');
            //     return;
            // }
            setSelectedFile(file);
            // setUploadFilerrorMessage('')
        }
    };

    const onConfirm = async () => {
        if (selectedFile) {
            try {
                await dispatch(addKnowledge({
                    file: selectedFile,
                    type: kbType,
                    name: knowledgeTitle,
                }))
            } catch (err) {
                console.log(err);
            }
        }

        onClosePopup()
    }

    return (
        <Dialog
            open={open}
            onClose={onClosePopup}
            title={'Add Knowledge Source'}
            description={description || 'Knowledge will be imported from the link.'}
            className={classes('addKnowledgeContainer', 'gap-6')}
            contentClassName="addKnowledgeContent"
        >
            <Flexbox fullWidth vertical className={classes('gap-6', 'py-6')}>
                <CommonInput
                    label={'Knowledge Title'}
                    placeholder={`New ${kbType}`}
                    inputType="filled"
                    value={knowledgeTitle}
                    onChange={(e) => { setKnowledgeTitle(e.target.value) }}
                    disabled={addKnowledgeLoading}
                />
                <Flexbox vertical className={classes('uploadKbSource', 'gap-3')}>
                    <UploadFileInput
                        className={classes('uploadKbSource-input')}
                        label={'Upload Your File'}
                        placeholder="Click here to upload"
                        inputType="filled"
                        type='file'
                        helperText={uploadFilerrorMessage || 'Connection Error. Retry Upload'}
                        error={!!uploadFilerrorMessage}
                        accept={'.csv, .pdf, .xlsx, .txt'}
                        onChange={handleFileChange}
                        multiple={false}
                        selectedFile={selectedFile}
                        disabled={addKnowledgeLoading}
                    />
                    <CustomTyphography className={classes('uploadKbSource-description')}>PDF, CSV, XLSX, TXT.</CustomTyphography>
                </Flexbox>

                {/* <Flexbox justifyBetween align>
                    <CustomTyphography className={classes('addNewNodeTitle')}>
                        Add New Nodes
                    </CustomTyphography>
                    <CommonSelect
                        value={{ name: 'Jira', id: 'jira' }}
                        options={[
                            { name: 'Jira', id: 'jira', icon: JiraSmallIcon },
                            { name: 'Confluence', id: 'confluence', icon: ConfluenceIcon }
                        ]}
                        getOptionLabel={option => option.name}
                        dropdownSize='medium'
                        dropdownVariant='filled'
                        valueKey='id'
                    />
                </Flexbox> */}
                {/* <Flexbox vertical className={classes('gap-5')}>
                    <Flexbox fullHeight vertical>
                        <Flexbox fullWidth className={classes('linkNodeInputBox')}>
                            <Flexbox align justify className={classes('linkNodeInputBox__left')}>
                                <CustomTyphography>Https://</CustomTyphography>
                            </Flexbox>
                            <Flexbox
                                align
                                justifyBetween
                                fullWidth
                                className={classes('linkNodeInputBox__right', 'gap-2')}
                            >
                                <TextField
                                    value={nodeLink}
                                    placeholder="https://example.atlassian.net/browse/PROJ-XXXX"
                                    className={classes('linkNodeInput')}
                                    onChange={e => setNodeLink(e.target.value)}
                                />
                                <CommonButton
                                    disabled={!nodeLink}
                                    onClick={() => handleLinkNode()}
                                    className={classes('linkNode-btn')}
                                    buttonSize="medium"
                                    buttonType="primary"
                                >
                                    Link
                                </CommonButton>
                            </Flexbox>
                        </Flexbox>
                        {!!nodeLink && !!errorMessage && (
                            <Flexbox className="input-error">{errorMessage}</Flexbox>
                        )}
                    </Flexbox>
                    <CustomTyphography className={classes('infoText')}>
                        This link will be used for AI planning, it can be Jira instant for example
                        of just a link for jira epic.
                    </CustomTyphography>
                </Flexbox> */}
            </Flexbox>
            <CommonButton
                fullWidth
                buttonSize="big"
                buttonType="primary"
                disabled={!knowledgeTitle || !selectedFile || addKnowledgeLoading}
                loading={addKnowledgeLoading}
                onClick={onConfirm}
            >
                Add Knowledge Source
            </CommonButton>

            <CustomSnackbar open={!!addKnowledgeError} type='error' title={addKnowledgeError} />
        </Dialog>
    );
};

export default AddKnowledgeSourcePopup;
